import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import vant from 'vant'
import 'vant/lib/index.css'

import '@/assets/styles/common.scss'
import '@/assets/styles/element.scss'

import '@/assets/iconfont/iconfont.css'
import '@/assets/iconfont/iconfont.js'

import './permission'

import micropageRequireComponent from '@/views/micropage/components'

const app = createApp(App)

// 全局注册element图标组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

micropageRequireComponent(app)

app.use(store)
app.use(router)
app.use(ElementPlus, { locale: zhCn })
app.use(vant)
app.mount('#app')

// 解决element表格（ResizeObserver loop completed with undelivered notifications.）宽度报错
const debounce = (fn, delay) => {
  let timer = null
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}
const _ResizeObserver = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ResizeObserver {
  constructor(callback) {
    callback = debounce(callback, 16)
    super(callback)
  }
}
