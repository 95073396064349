<template>
  <div v-if="false" class="custommodule">
    {{ datas.demo }}
    <img :src="datas.img" alt="" />
    <!-- 删除组件 -->
    <slot name="deles" />
  </div>
  <div v-else>
    <img src="@/assets/images/micropage-push.png" class="w-100" />
  </div>
</template>

<script>
export default {
  name: 'custommodule',
  props: {
    datas: Object
  }
}
</script>

<style scoped lang="scss">
.custommodule {
  position: relative;
  img {
    width: 100%;
  }
}
</style>
