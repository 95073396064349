import { createStore } from 'vuex'

import getters from './getters'
import user from './modules/user'

export default createStore({
  state: {},
  getters,
  mutations: {
    resetData() {
      const modules = ['user']
      modules.forEach((item) => {
        this.commit(`${item}/resetData`)
      })
    }
  },
  actions: {},
  modules: {
    user
  }
})
