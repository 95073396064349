<template>
  <div class="sidebar-container">
    <el-menu :default-active="route.path" router>
      <MenuItem v-for="item in menuList" :key="item.path" :menu="item" />
    </el-menu>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import MenuItem from './MenuItem'

const route = useRoute()
const store = useStore()

const menuList = computed(() => {
  return store.state.user.sidebarMenu
})
</script>

<style lang="scss" scoped></style>
