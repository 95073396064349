import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import { ElMessage } from 'element-plus'

const BASE_URL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_SERVER_URL

const requset = axios.create({
  timeout: 100000,
  headers: {
    'Content-Type': 'application/json'
  },
  baseURL: BASE_URL
})

requset.interceptors.request.use(
  (config) => {
    const { token } = store.getters
    const requsetType = config.method

    config.headers.userToken = token

    if (requsetType === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

requset.interceptors.response.use(
  (res) => {
    const { code, msg } = res.data
    if (code === 403) {
      ElMessage.error('登陆过期，请重新登陆')
      store.commit('user/logout')
      return Promise.reject(res.data)
    } else if (code !== 200) {
      ElMessage.error(msg)
      return Promise.reject(res.data)
    }
    return Promise.resolve(res.data)
  },
  (error) => {
    if (error.response.status === 404) {
      ElMessage.error('404 找不到路径' + error.response.config.url)
    } else {
      ElMessage.error(error.response.data.msg || '连接不到服务器，请稍后重试')
    }
    return Promise.reject(error)
  }
)

export default requset
