<template>
  <div>
    <el-upload
      v-bind="$attrs"
      ref="uploadRef"
      drag
      name="files"
      :action="uploadUrl"
      :headers="headers"
      :limit="limit"
      :list-type="listType"
      :file-list="fileList"
      :before-upload="handleBeforeUpload"
      :on-exceed="handleExceed"
      :on-remove="handleRemove"
      :on-preview="handlePreview"
      :on-success="handleSuccess"
    >
      <slot>
        <el-icon class="el-icon--upload"><upload-filled /></el-icon>
        <div class="el-upload__text">将文件拖拽到此或<em>点击上传</em></div>
      </slot>
    </el-upload>

    <el-dialog v-model="previewVisible" title="预览" append-to-body>
      <div class="center">
        <img v-if="urlWhetherImage(previewUrl)" :src="previewUrl" class="preview-file" />
        <video v-else :src="previewUrl" controls class="preview-file"></video>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="previewVisible = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
/**
 * 上传文件
 */
import { ref, computed } from 'vue'
import { useStore } from 'vuex'
import { ElMessage } from 'element-plus'

import { urlWhetherImage, urlWhetherVideo, urlWhetherOpen } from '@/utils/file'

const props = defineProps({
  modelValue: {},
  // 最大上传数量
  limit: {
    type: Number,
    default: 1
  },
  // 上传文件最大size 单位：M
  size: {
    type: Number,
    default: 50
  },
  // 文件列表的类型展示UI类型
  listType: {
    type: String,
    default: 'picture'
  }
})
const emit = defineEmits(['update:modelValue'])

const { getters } = useStore()

const uploadUrl = process.env.VUE_APP_SERVER_URL + '/api/common/uploadFile'
const headers = { userToken: getters.token }

const uploadRef = ref(null)

const fileList = computed(() => {
  if (Array.isArray(props.modelValue)) {
    return props.modelValue.map((url) => {
      return { name: url.split('/').pop(), url }
    })
  } else if (props.modelValue && typeof props.modelValue === 'string') {
    return [{ name: props.modelValue.split('/').pop(), url: props.modelValue }]
  }
  return []
})

// 上传之前
const handleBeforeUpload = (file) => {
  const isSize = file.size / 1024 / 1024 < props.size
  if (!isSize) {
    ElMessage.warning(`上传文件大小不能超过${props.size}M`)
  }
  return isSize
}

// 上传数量超过限制
const handleExceed = () => {
  ElMessage.warning(`文件最大上传数量为${props.limit}个`)
}

// 删除某已上传文件
const handleRemove = (file, files) => {
  const list = files.map((item) => item.url)
  emit('update:modelValue', props.limit > 1 ? list : list[0] || '')
}

// 预览
const previewVisible = ref(false)
const previewUrl = ref('')
const handlePreview = (file) => {
  if (urlWhetherImage(file.url) || urlWhetherVideo(file.url)) {
    previewUrl.value = file.url
    previewVisible.value = true
  } else if (urlWhetherOpen(file.url)) {
    window.open(file.url)
  }
}

// 上传成功
const handleSuccess = (res, uploadFile, uploadFiles) => {
  if (uploadFiles.every((file) => file.status === 'success')) {
    const list = uploadFiles.map((file) => file.response?.data[0].url || file.url)
    emit('update:modelValue', props.limit > 1 ? list : list[0])
  }
}

// 清空上传文件
const clearFiles = () => {
  emit('update:modelValue', props.limit > 1 ? [] : '')
  uploadRef.value.clearFiles()
}

defineExpose({ clearFiles })
</script>

<style lang="scss" scoped>
.preview-file {
  object-fit: contain;
  max-width: 70vh;
}
</style>
