export const IMAGE_TYPE = ['bmp', 'jpg', 'jpeg', 'png', 'tif', 'gif', 'pcx', 'tga', 'exif', 'fpx', 'svg', 'psd', 'cdr', 'pcd', 'dxf', 'ufo', 'eps', 'ai', 'raw', 'WMF', 'webp', 'avif', 'apng']
export const VIDEO_TYPE = ['avi', 'wmv', 'mpeg', 'mp4', 'm4v', 'mov', 'asf', 'flv', 'f4v', 'rmvb', 'rm', '3gp', 'vob', 'MOV']
// 可打开预览文件类型列表
export const openTypeList = ['xls', 'ppt', 'pdf', 'xlsx', 'pptx']

/**
 * 获取文件后缀名
 */
export const getFileLastName = (url) => {
  const lastIndex = url.lastIndexOf('.')
  return url.substr(lastIndex + 1)
}

/**
 * url是否图片文件
 */
export const urlWhetherImage = (url) => {
  const fileType = getFileLastName(url)
  return IMAGE_TYPE.includes(fileType)
}

/**
 * url是否视频文件
 */
export const urlWhetherVideo = (url) => {
  const fileType = getFileLastName(url)
  return VIDEO_TYPE.includes(fileType)
}

/**
 * url是否可预览文件
 */
export const urlWhetherOpen = (url) => {
  const fileType = getFileLastName(url)
  return openTypeList.includes(fileType)
}
