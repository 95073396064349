<template>
  <div class="page">
    <div v-loading="loading.homeData">
      <div v-for="(item, index) in modelList" :key="index" class="model-box">
        <div v-for="model in item" :key="model.value" class="info-data">
          <div class="data-value">{{ homeData[model.value] || '0' }}</div>
          <div class="data-label">{{ model.label }}</div>
        </div>
      </div>
    </div>

    <div id="chart" class="chart-box"></div>

    <div class="flex justify-between">
      <div v-loading="loading.goodSaleRank" class="leaderboard">
        <div>商品购买力排行</div>
        <el-divider />
        <el-table :data="goodSaleRank.list">
          <el-table-column label="排名" prop="rank" width="70"></el-table-column>
          <el-table-column label="商品" prop="name" min-width="120"></el-table-column>
          <el-table-column label="销售额(元)" prop="amount" min-width="120"></el-table-column>
          <el-table-column label="销量(件)" prop="cnt" min-width="120"></el-table-column>
        </el-table>
        <el-pagination
          v-model:current-page="goodSaleRank.page"
          :total="goodSaleRank.total"
          small
          background
          hide-on-single-page
          layout="prev, pager, next"
          @current-change="getGoodSaleRank"
        />
      </div>

      <div v-loading="loading.userBuyRank" class="leaderboard">
        <div>用户购买力排行</div>
        <el-divider />
        <el-table :data="userBuyRank.list">
          <el-table-column label="排名" prop="rank" width="70"></el-table-column>
          <el-table-column label="用户" prop="name" min-width="120"></el-table-column>
          <el-table-column label="支付金额(元)" prop="amount" min-width="120"></el-table-column>
          <el-table-column label="支付件数(件)" prop="cnt" min-width="120"></el-table-column>
        </el-table>
        <el-pagination
          v-model:current-page="userBuyRank.page"
          :total="userBuyRank.total"
          small
          background
          hide-on-single-page
          layout="prev, pager, next"
          @current-change="getUserBuyRank"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import * as echarts from 'echarts'

import { queryHomeData, queryHomestatisticsList, queryUserBuyRank, queryGoodSaleRank } from '@/api/home'

const modelList = [
  [
    { label: '总销售额', value: 'totalSaleAmount' },
    { label: '订单总额', value: 'totalAmount' },
    { label: '总注册用户', value: 'totalUserCnt' },
    { label: '本周新注册用户', value: 'weekUserCnt' }
  ],
  [
    { label: '在售数量', value: 'saleCnt' },
    { label: '提现金额', value: 'withdrawAmount' },
    { label: '退款金额', value: 'refundAmount' },
    { label: '申请提现金额', value: 'applyWithdrawAmount' }
  ],
  [
    { label: '支付金额', value: 'payAmount' },
    { label: '支付人数', value: 'payUserCnt' },
    { label: '支付订单数', value: 'payOrderCnt' },
    { label: '支付商品数', value: 'payProductCnt' }
  ]
]

const loading = ref({})

onMounted(() => {
  getHomeData()
  getHomestatisticsList()
  getUserBuyRank()
  getGoodSaleRank()
})

const homeData = ref({})
const getHomeData = async () => {
  loading.value.homeData = true
  try {
    const { data } = await queryHomeData()
    homeData.value = data
  } finally {
    loading.value.homeData = false
  }
}

const legendData = [
  { name: '支付金额', key: 'payAmount' },
  { name: '支付人数', key: 'payUserCnt' },
  { name: '支付订单数', key: 'payOrderCnt' },
  { name: '支付商品数', key: 'payProductCnt' }
]
const getHomestatisticsList = async () => {
  const chartDom = document.getElementById('chart')
  const myChart = echarts.init(chartDom)
  const option = {
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: legendData.map((item) => item.name)
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: []
    },
    yAxis: {
      type: 'value'
    },
    series: []
  }

  const nowDate = new Date()
  const year = nowDate.getFullYear()
  const month = nowDate.getMonth()
  const endAt = new Date(year, month, getLastDay(year, month + 1), 23, 59, 59)
  const startAt = new Date(year, month, 1)
  // 获取某月最后一天
  function getLastDay(year, month) {
    return new Date(new Date(`${month < 12 ? year : ++year}-${month === 12 ? 1 : ++month} 00:00`).getTime() - 1).getDate()
  }

  const { data } = await queryHomestatisticsList({ startAt, endAt })
  option.xAxis.data = data.map((item) => item.time)
  option.series = legendData.map(({ name, key }) => {
    return {
      name,
      type: 'line',
      stack: 'Total',
      data: data.map((item) => item[key])
    }
  })
  myChart.setOption(option)
}

const userBuyRank = ref({
  list: [],
  page: 1,
  limit: 10
})
const getUserBuyRank = async () => {
  loading.value.userBuyRank = true
  try {
    const { data, total } = await queryUserBuyRank({ limit: userBuyRank.value.limit, page: userBuyRank.value.page })
    userBuyRank.value.list = data
    userBuyRank.value.total = total
  } finally {
    loading.value.userBuyRank = false
  }
}

const goodSaleRank = ref({
  list: [],
  page: 1,
  limit: 10
})
const getGoodSaleRank = async () => {
  loading.value.goodSaleRank = true
  try {
    const { data, total } = await queryGoodSaleRank({ limit: goodSaleRank.value.limit, page: goodSaleRank.value.page })
    goodSaleRank.value.list = data
    goodSaleRank.value.total = total
  } finally {
    loading.value.goodSaleRank = false
  }
}
</script>

<style lang="scss" scoped>
.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(240, 242, 245);
  overflow: auto;
}
.model-box {
  margin-bottom: 10px;
  padding: 50px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  display: flex;
}
.info-data {
  text-align: center;
  flex: 1;
  border-left: 1px dashed #eff1f7;
}
.data-value {
  color: #409eff;
  font-size: 28px;
}
.data-label {
  color: #92959b;
  font-size: 16px;
}
.chart-box {
  height: 300px;
  margin-bottom: 10px;
  padding: 20px;
  background-color: #fff;
}
.leaderboard {
  width: 49%;
  overflow: auto;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}
</style>
