import router from './router'
import store from './store'

router.beforeEach(async (to, from) => {
  const { token, userInfo } = store.getters
  const { roleAuthKeys } = store.state.user
  const whiteList = ['/login']

  if (!token) {
    if (!whiteList.includes(to.path)) {
      return '/login'
    }
    return
  }

  if (to.path === '/login') {
    return '/'
  }

  if (!userInfo) {
    await store.dispatch('user/getUserInfo')
  }
  if (!roleAuthKeys || roleAuthKeys.length === 0) {
    await store.dispatch('user/getRoleAuthKeys')
    return to.path
  }
})
