import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'
import Home from '@/views/home'
import Login from '@/views/login'
import page404 from '@/views/404'

export const constantRoutes = [
  {
    path: '/',
    component: Layout,
    meta: {
      noMenu: true
    },
    children: [
      {
        path: '/',
        name: 'Home',
        meta: {
          title: '首页',
          icon: 'Monitor'
        },
        component: Home
      }
    ]
  },
  {
    path: '/login',
    component: Login,
    meta: {
      noMenu: true
    }
  },
  {
    path: '/commom',
    name: 'Commom',
    component: Layout,
    meta: {
      noMenu: true
    },
    children: [
      {
        path: '/commom/userCenter',
        name: 'CommonUserCenter',
        component: () => import(/* webpackChunkName: "UserCenter" */ '@/views/userManage/userCenter/index.vue'),
        meta: {
          noMenu: true,
          title: '个人中心'
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)',
    name: '404',
    component: page404,
    meta: {
      noMenu: true
    }
  }
]

const syncRoutes = [
  {
    path: '/micropage',
    name: 'Micropage',
    component: Layout,
    meta: {
      noMenu: true
    },
    children: [
      {
        path: '/micropage/index',
        name: 'MicropageIndex',
        meta: {
          title: '店铺装修',
          icon: 'Tickets'
        },
        component: () => import(/* webpackChunkName: "MicropageIndex" */ '@/views/micropage/index.vue')
      }
    ]
  },
  {
    path: '/commodityManage',
    name: 'CommodityManage',
    component: Layout,
    meta: {
      title: '商城管理',
      icon: 'Goods'
    },
    children: [
      {
        path: '/commodityManage/group',
        name: 'CommodityManageGroup',
        meta: {
          title: '商品分类'
        },
        component: () => import(/* webpackChunkName: "CommodityManageGroup" */ '@/views/commodityManage/group/index.vue')
      },
      {
        path: '/commodityManage/specification',
        name: 'CommodityManageSpecification',
        meta: {
          title: '商品规格'
        },
        component: () => import(/* webpackChunkName: "CommodityManageSpecification" */ '@/views/commodityManage/specification/index.vue')
      },
      {
        path: '/commodityManage/list',
        name: 'CommodityManageList',
        meta: {
          title: '商品列表'
        },
        component: () => import(/* webpackChunkName: "CommodityManageList" */ '@/views/commodityManage/list/index.vue')
      },
      {
        path: '/commodityManage/marketing',
        name: 'CommodityMarketing',
        meta: {
          title: '营销活动'
        },
        component: () => import(/* webpackChunkName: "CommodityMarketing" */ '@/views/commodityManage/marketing/index.vue')
      }
    ]
  },
  {
    path: '/orderManage',
    name: 'OrderManage',
    component: Layout,
    meta: {
      title: '订单管理',
      icon: 'Tickets'
    },
    children: [
      {
        path: '/orderManage/list',
        name: 'OrderManageList',
        meta: {
          title: '订单列表'
        },
        component: () => import(/* webpackChunkName: "OrderManageList" */ '@/views/orderManage/list/index.vue')
      },
      {
        path: '/orderManage/particulars',
        name: 'OrderManageParticulars',
        meta: {
          title: '订单明细表'
        },
        component: () => import(/* webpackChunkName: "OrderManageParticulars" */ '@/views/orderManage/particulars/index.vue')
      },
      {
        path: '/orderManage/statistics',
        name: 'OrderManageStatistics',
        meta: {
          title: '订单统计表'
        },
        component: () => import(/* webpackChunkName: "OrderManageStatistics" */ '@/views/orderManage/statistics/index.vue')
      }
    ]
  },
  {
    path: '/couponManage',
    name: 'CouponManage',
    component: Layout,
    meta: {
      noMenu: true
    },
    children: [
      {
        path: '/couponManage/list',
        name: 'CouponManageList',
        meta: {
          title: '优惠券管理',
          icon: 'Discount'
        },
        component: () => import(/* webpackChunkName: "CouponManageList" */ '@/views/couponManage/list/index.vue')
      }
    ]
  },
  {
    path: '/vipManage',
    name: 'VipManage',
    component: Layout,
    meta: {
      title: '会员管理',
      icon: 'Present'
    },
    children: [
      {
        path: '/vipManage/packageList',
        name: 'VipManagePackageList',
        meta: {
          title: '会员套餐管理'
        },
        component: () => import(/* webpackChunkName: "VipManagePackageList" */ '@/views/vipManage/packageList/index.vue')
      },
      {
        path: '/vipManage/userList',
        name: 'VipManageUserList',
        meta: {
          title: '会员列表'
        },
        component: () => import(/* webpackChunkName: "VipManageUserList" */ '@/views/vipManage/userList/index.vue')
      }
    ]
  },
  {
    path: '/userManage',
    name: 'UserManage',
    component: Layout,
    meta: {
      title: '用户管理',
      icon: 'User'
    },
    children: [
      {
        path: '/userManage/userList',
        name: 'UserManageUserList',
        meta: {
          title: '用户列表'
        },
        component: () => import(/* webpackChunkName: "CommodityManageGroup" */ '@/views/userManage/userList/index.vue')
      },
      {
        path: '/userManage/userAuth',
        name: 'UserManageUserAuth',
        meta: {
          title: '用户认证'
        },
        component: () => import(/* webpackChunkName: "UserManageUserAuth" */ '@/views/userManage/userAuth/index.vue')
      },
      {
        path: '/userManage/distributorList',
        name: 'UserManageDistributorList',
        meta: {
          title: '分销商列表'
        },
        component: () => import(/* webpackChunkName: "UserManageDistributorList" */ '@/views/userManage/distributorList/index.vue')
      }
    ]
  },
  {
    path: '/financeManage',
    name: 'FinanceManage',
    component: Layout,
    meta: {
      title: '财务管理',
      icon: 'Wallet'
    },
    children: [
      {
        path: '/financeManage/invoice',
        name: 'FinanceManageInvoice',
        meta: {
          title: '发票申请'
        },
        component: () => import(/* webpackChunkName: "FinanceManageInvoice" */ '@/views/financeManage/invoice/index.vue')
      },
      {
        path: '/financeManage/withdraw',
        name: 'FinanceManageWithdraw',
        meta: {
          title: '提现申请'
        },
        component: () => import(/* webpackChunkName: "FinanceManageWithdraw" */ '@/views/financeManage/withdraw/index.vue')
      },
      {
        path: '/financeManage/rebate',
        name: 'FinanceManageRebate',
        meta: {
          title: '返利列表'
        },
        component: () => import(/* webpackChunkName: "FinanceManageRebate" */ '@/views/financeManage/rebate/index.vue')
      }
    ]
  },
  {
    path: '/systemSetting',
    name: 'SystemSetting',
    component: Layout,
    meta: {
      title: '系统设置',
      icon: 'Setting'
    },
    children: [
      {
        path: '/bannerManage',
        name: 'BannerManage',
        meta: {
          title: '前台轮播图'
        },
        component: () => import(/* webpackChunkName: "OrderManage" */ '@/views/systemSetting/bannerManage/index.vue')
      },
      // {
      //   path: '/systemSetting/notify',
      //   name: 'SystemSettingNotify',
      //   meta: {
      //     title: '系统通知'
      //   },
      //   component: () => import(/* webpackChunkName: "CommodityManageList" */ '@/views/systemSetting/notify/index.vue')
      // },
      {
        path: '/systemSetting/transFee',
        name: 'SystemSettingTransFee',
        meta: {
          title: '运费设置'
        },
        component: () => import(/* webpackChunkName: "CommodityManageList" */ '@/views/systemSetting/transFee/index.vue')
      },
      {
        path: '/systemSetting/baseConfig',
        name: 'SystemSettinBaseConfig',
        meta: {
          title: '基础配置'
        },
        component: () => import(/* webpackChunkName: "CommodityManageGroup" */ '@/views/systemSetting/baseConfig/index.vue')
      },
      {
        path: '/systemSetting/systemUserManage',
        name: 'SystemSettinSystemUserManage',
        meta: {
          title: '系统管理员列表'
        },
        component: () => import(/* webpackChunkName: "CommodityManageGroup" */ '@/views/systemSetting/systemUserManage/index.vue')
      },
      {
        path: '/systemSetting/roleManage',
        name: 'SystemSettingRoleManage',
        meta: {
          title: '角色列表'
        },
        component: () => import(/* webpackChunkName: "CommodityManageList" */ '@/views/systemSetting/roleManage/index.vue')
      }
    ]
  }
]

export function getSyncRoutes() {
  return [...syncRoutes]
}

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

export default router
