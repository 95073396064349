import request from '@/utils/request'

// 登陆
export const login = (data) => {
  return request({
    url: '/api/sysUser/login',
    method: 'post',
    data
  })
}

// 获取系统管理员用户信息
export const queryUserInfo = () => {
  return request({
    url: '/api/sysUser/userInfo',
    method: 'get'
  })
}

// 修改用户信息
export const updateUserInfo = (data) => {
  return request({
    url: '/api/sysUser/updateInfo',
    method: 'post',
    data
  })
}

// 修改密码
export const updateUserPassword = (data) => {
  return request({
    url: '/api/sysUser/updatePass',
    method: 'post',
    data
  })
}

// 获取用户认证列表
export const queryUserAuthList = (params) => {
  return request({
    url: '/api/auth/getList',
    method: 'get',
    params
  })
}

// 获取用户认证详情
export const queryUserAuthDetail = (params) => {
  return request({
    url: '/api/auth/getInfo',
    method: 'get',
    params
  })
}

// 审核用户认证
export const checkUserAuth = (data) => {
  return request({
    url: '/api/auth/auth',
    method: 'post',
    data
  })
}

// 获取分销商列表
export const queryDistributorList = (params) => {
  return request({
    url: '/api/distributor/getList',
    method: 'get',
    params
  })
}

// 获取分销商详情
export const queryDistributorDetail = (params) => {
  return request({
    url: '/api/distributor/getInfo',
    method: 'get',
    params
  })
}

// 审核分销商
export const checkDistributor = (data) => {
  return request({
    url: '/api/distributor/auth',
    method: 'post',
    data
  })
}
