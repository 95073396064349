/**
 * 用户模块
 */
import { setData } from '../util'
import router, { constantRoutes } from '@/router'
import { getRoleRoutes, getSidebarMenu } from '@/router/util'

import { TOKEN, USER_INFO } from '@/constant/user'

import { setStorage, getStorage, removeStorage } from '@/utils/storage'

import { login, queryUserInfo } from '@/api/user'

function getDefaultState() {
  return {
    token: getStorage(TOKEN),
    userInfo: getStorage(USER_INFO), // 当前用户信息
    roleAuthKeys: [], // 当前用户角色权限key集合
    routes: [], // 当前用户的路由数据
    sidebarMenu: [] // 侧边栏菜单
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetData(state) {
      Object.assign(state, getDefaultState())
    },
    setData,
    setToken(state, token) {
      state.token = token
      setStorage({ key: TOKEN, value: token })
    },
    setUserInfo(state, info) {
      state.userInfo = info
      setStorage({ key: USER_INFO, value: info })
    },
    logout() {
      const list = [TOKEN, USER_INFO]
      list.forEach((key) => {
        removeStorage(key)
        removeStorage(key, 'sessionStorage')
      })
      this.commit('resetData')
      router.push({ path: '/login' })
    }
  },
  actions: {
    async login({ commit }, data) {
      const res = await login(data)
      console.log('res', res)
      commit('setToken', res.data.token)
    },
    async getUserInfo({ commit }, id) {
      const { data } = await queryUserInfo()
      commit('setUserInfo', data)
    },
    async getRoleAuthKeys({ state, commit }) {
      const { role } = state.userInfo
      commit('setData', { roleAuthKeys: role.content === '*' ? '*' : JSON.parse(role.content) })
      // 调试使用 - 全权限
      // commit('setData', {
      //   roleAuthKeys: ['*']
      // })

      const roleRoutes = getRoleRoutes()
      const routes = constantRoutes.concat(roleRoutes)
      const sidebarMenu = getSidebarMenu(routes)
      roleRoutes.forEach((route) => router.addRoute(route))
      commit('setData', {
        routes: constantRoutes.concat(routes),
        sidebarMenu
      })
    }
  }
}
