import { getSyncRoutes } from '@/router'
import store from '@/store'

// 获取当前角色的路由
export function getRoleRoutes() {
  const { roleAuthKeys } = store.state.user
  return formatRoutes(getSyncRoutes())

  function formatRoutes(list) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (
        !roleAuthKeys.includes('*') &&
        !roleAuthKeys.includes(item.name) &&
        (!item.children || item.children.length === 0 || !checkRouteChildrenRolePass(item.children))
      ) {
        // 删除没有权限的路由
        list.splice(i, 1)
        i--
      } else if (item.children?.length > 0) {
        formatRoutes(item.children)
        // 设置路由的重定向值
        item.redirect = item.children[0].path
      }
    }
    return list
  }
  // 获取当前路由的所有子路由 是否有符合用户权限的
  function checkRouteChildrenRolePass(list) {
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      if (roleAuthKeys.includes(item.name)) {
        return true
      } else if (item.children?.length > 0) {
        return checkRouteChildrenRolePass(item.children)
      }
    }
    return false
  }
}

// 获取侧边栏菜单数据
export function getSidebarMenu(list) {
  list = list || store.state.user.routes
  const menuList = []
  for (let i = 0; i < list.length; i++) {
    const item = list[i]
    if (!item.meta.noMenu) {
      const menu = { ...item, children: item.children?.length > 0 ? getSidebarMenu(item.children) : [] }
      menuList.push(menu)
    } else if (item.children?.length > 0) {
      menuList.push(...getSidebarMenu(item.children))
    }
  }

  return menuList
}

// 获取角色权限树形结构数据
export function getRoleTree() {
  const routes = getSidebarMenu(getSyncRoutes())
  return getTreeLabel()

  function getTreeLabel(list = routes) {
    const tree = []
    for (let i = 0; i < list.length; i++) {
      const item = list[i]
      const data = { ...item, label: item.meta.title, children: item.children?.length > 0 ? getTreeLabel(item.children) : [] }
      tree.push(data)
    }
    return tree
  }
}
