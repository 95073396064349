export const TOKEN = 'token'
export const USER_INFO = 'userInfo'

export const USER_TYPE = {
  0: '个人',
  1: '药房',
  2: '公立医院',
  3: '诊所/民营医院',
  4: '机构'
}
export const USER_TYPE_LIST = [
  { label: '个人', value: 0 },
  { label: '药房', value: 1 },
  { label: '公立医院', value: 2 },
  { label: '诊所/民营医院', value: 3 },
  { label: '机构', value: 4 }
]
