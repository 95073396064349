import request from '@/utils/request'

// 获取首页统计
export const queryHomeData = () => {
  return request({
    url: '/api/home/statistics',
    method: 'get'
  })
}

// 获取首页统计列表
export const queryHomestatisticsList = (params) => {
  return request({
    url: '/api/home/statisticsList',
    method: 'get',
    params
  })
}

// 获取商品购买力排行
export const queryGoodSaleRank = (params) => {
  return request({
    url: '/api/home/goodSaleRank',
    method: 'get',
    params
  })
}

// 获取用户购买力排行
export const queryUserBuyRank = (params) => {
  return request({
    url: '/api/home/userBuyRank',
    method: 'get',
    params
  })
}
