<template>
  <div class="navbar-container">
    <div class="navbar-title">医贸通后台</div>

    <el-dropdown @command="handleCommand">
      <div class="flex align-center pointer" style="outline: none">
        <el-avatar :size="30" src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" />
        <div class="ml-10 white size-14">{{ userInfo.nickname }}</div>
      </div>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item command="userCenter">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout" divided>退出登陆</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const router = useRouter()

const { userInfo } = store.getters

const handleCommand = (e) => {
  switch (e) {
    case 'userCenter':
      router.push({ path: '/commom/userCenter' })
      break
    case 'logout':
      store.commit('user/logout')
      break
  }
}
</script>

<style lang="scss" scoped></style>
