<template>
  <div class="login-container">
    <div class="login-form">
      <div class="form-title">系统登陆</div>
      <el-form :model="form" :rules="rules" ref="formRef">
        <el-form-item prop="account">
          <el-input v-model="form.account" placeholder="账号" :prefix-icon="User"></el-input>
        </el-form-item>
        <el-form-item prop="passwork">
          <el-input v-model="form.password" placeholder="密码" :prefix-icon="Lock" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <div class="w-100 flex justify-end">
            <el-checkbox v-model="record">记住密码</el-checkbox>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button :loading="loading" type="primary" @click="handleLogin">登陆</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { User, Lock } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'

const { dispatch } = useStore()
const router = useRouter()
const form = ref({})
const rules = {
  account: { required: true, message: '请输入账号', trigger: 'blur' },
  password: { required: true, message: '请输入密码', trigger: 'blur' }
}
const formRef = ref(null)
const record = ref(false)
const loading = ref(false)

onMounted(() => {
  const recordData = window.localStorage.getItem('recordData')
  if (recordData) {
    form.value = JSON.parse(recordData)
    record.value = true
  }
})

const handleLogin = async () => {
  await formRef.value.validate()

  loading.value = true
  try {
    await dispatch('user/login', form.value)
    ElMessage.success('登陆成功')
    // 记住密码
    const recordData = record.value ? JSON.stringify(form.value) : ''
    window.localStorage.setItem('recordData', recordData)
    router.push({ path: '/' })
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  background-color: #2d3a4b;
  display: flex;
  align-items: center;
  justify-content: center;
  .login-form {
    width: 520px;
    .form-title {
      font-size: 26px;
      color: #eee;
      margin: 0 auto 40px auto;
      text-align: center;
      font-weight: 700;
    }
    :deep(.el-input__wrapper) {
      height: 48px;
      background-color: rgba(0, 0, 0, 0.1);
      border: 1px solid hsla(0, 0%, 100%, 0.1);
      box-shadow: none;
    }
    :deep(.el-input__inner) {
      color: #fff;
    }
    :deep(.el-button) {
      width: 100%;
      height: 38px;
    }
  }
}
</style>
