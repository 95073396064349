<template>
  <div class="tabbar-container border-box flex align-center justify-between">
    <div
      v-for="(item, index) in tabbar"
      :key="item.path"
      class="tabbar-item flex flex-col align-center relative"
      :style="{ width: 100 / tabbar.length + '%' }"
      @click="activeIndex = index"
    >
      <img :src="index === activeIndex ? item.activeImage : item.image" />
      <span>{{ item.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'phoneBottom',
  props: ['tabbar'],
  data() {
    return {
      activeIndex: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar-container {
  height: 45px;
  margin-top: 20px;
  padding-top: 4px;
  background-color: #fff;
  border-top: 1px solid #eeeeee;
  .tabbar-item {
    height: 100%;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      margin-bottom: 2px;
    }
    span {
      font-size: 10px;
    }
  }
}
</style>
