<template>
  <div class="app-main-container">
    <div class="container-view">
      <router-view> </router-view>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped></style>
